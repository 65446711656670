<template>
<div class="student-details-panel tab-pane active show p-3">
    <div class="image-header kt-widget kt-widget--user-profile-4">
        <div class="kt-widget__head">
            <div class="kt-widget__media">
                <StudentPortfolioAvatar
                    :student="student"
                />
            </div>

            <div class="kt-widget__content">
                <div class="kt-widget__section">
                    <div v-if="!encryptionEnabled" class="kt-widget__username pb-1 pt-4 w-100">
                        {{ student.lastName }}, {{ student.firstName }}
                    </div>
                    <div v-else class="kt-widget__username pb-1 pt-4 w-100">
                        {{ student.maskedStudentName }}
                    </div>
                    <div
                        v-if="!isHomeUser && administrativeBadges.length && academicAwards.length"
                        class="badge-container text-center pt-4"
                    >
                        <div class="text-muted d-none">
                            Badges &amp; Awards
                        </div>
                        <span
                            v-for="(item) in administrativeBadges"
                            :key="`badge_${item.badge.badgeId}`"
                            v-b-tooltip.hover
                            role="button"
                            :title="`${item.count}x ${item.badge.badgeTitle}`"
                            class="mx-1"
                            @click.stop.prevent="showStudentBadgesRoute"
                        >
                            {{ item.badge.badgeIcon }}
                        </span>
                        <span
                            v-for="(item) in academicAwards"
                            :key="`badge_${item.badge.badgeId}`"
                            v-b-tooltip.hover
                            role="button"
                            :title="`${item.count}x ${item.badge.badgeTitle}`"
                            class="mx-1"
                            @click.stop.prevent="showStudentBadgesRoute"
                        >
                            {{ item.badge.badgeIcon }}
                        </span>
                    </div>
                    <div class="kt-widget__action my-3">
                        <a
                            v-b-tooltip.hover.bottom="`Create Anecdotal`"
                            href="#"
                            class="btn btn-icon btn-circle btn-label-twitter m-1"
                            @click.stop.prevent="newAnecdotal"
                        >
                            <i class="la la-file-text" style="font-size: 1.4rem !important" />
                        </a>
                        <a
                            v-if="hasBadges"
                            v-b-tooltip.hover.bottom="`Add Award / Badge`"
                            href="#"
                            class="btn btn-icon btn-circle btn-label-linkedin m-1"
                            @click.stop.prevent="newStudentBadge"
                        >
                            <i class="la la-smile-o" style="font-size: 1.5rem !important" />
                        </a>
                        <b-dropdown
                            v-if="!isHomeUser && canMessageHomeUsers"
                            class="m-1"
                            toggle-class="px-0"
                            variant="none"
                            no-caret
                        >
                            <template #button-content>
                                <button
                                    v-b-tooltip.hover.top="`Create Message`"
                                    class="btn btn-icon btn-circle btn-label-google"
                                >
                                    <i class="la la-comments" style="font-size: 1.5rem !important" />
                                </button>
                            </template>
                            <b-dropdown-item
                                variant="none"
                                button-class="dropdown-item"
                                link-class="kt-link"
                                @click="toggleCreateMessageToStudent"
                            >
                                <SVGIcon
                                    name="communication"
                                    class="kt-svg-icon kt-svg-icon--sm mr-2"
                                />
                                Message Student
                            </b-dropdown-item>
                            <b-dropdown-item
                                variant="none"
                                button-class="dropdown-item"
                                link-class="kt-link"
                                @click="toggleCreateMessageToGuardian"
                            >
                                <SVGIcon
                                    name="guardians"
                                    class="kt-svg-icon kt-svg-icon--sm mr-2"
                                />
                                Message Guardian
                            </b-dropdown-item>

                            <slot name="toolbar-dropdown" />
                        </b-dropdown>
                    </div>

                    <div class="pb-1 pt-1 w-100 text-center">
                        <router-link
                            v-if="extCourseSectionId"
                            class="btn btn-label-brand btn-bold btn-upper"
                            :to="{
                                name: 'StudentCourseOverview',
                                params: {
                                    studentEnrollmentId: student.studentEnrollmentId,
                                    extCourseSectionId
                                },
                            }"
                        >
                            <i class="la la-user" />
                            Open Portfolio
                        </router-link>
                        <router-link
                            v-else
                            class="btn btn-label-brand btn-bold btn-upper"
                            :to="{
                                name: 'StudentInfo',
                                params: {
                                    studentEnrollmentId: student.studentEnrollmentId,
                                },
                            }"
                        >
                            <i class="la la-user" />
                            Open Portfolio
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ul class="nav nav-tabs nav-tabs-line pt-3" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                href="#"
                :class="{ 'active': selectedPane == 'bio' }"
                @click.stop.prevent="selectPane('bio')"
            >
                Biographical
            </a>
        </li>

        <li class="nav-item">
            <a
                class="nav-link"
                href="#"
                :class="{ 'active': selectedPane == 'courses' }"
                @click.stop.prevent="selectPane('courses')"
            >
                Courses
            </a>
        </li>

        <li class="nav-item">
            <a
                class="nav-link"
                href="#"
                :class="{ 'active': selectedPane == 'anecdotals' }"
                @click.stop.prevent="selectPane('anecdotals')"
            >
                Anecdotals
            </a>
        </li>
    </ul>

    <div v-if="selectedPane == 'anecdotals'" class="data-fields py-3 w-100">
        <StudentAnecdotalFilters :student-id="student.studentId" />
    </div>

    <StudentDetailCourseList v-if="selectedPane == 'courses'" :student="student" />

    <div v-if="selectedPane == 'bio'" class="data-fields kt-widget28__tab-items py-3 row w-100">
        <div
            v-if="!encryptionEnabled"
            class="col-4 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Student Id
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(student.extStudentId)"
            >
                <i class="la la-copy mr-1" />
                {{ student.extStudentId.substring(0,3) }}-{{ student.extStudentId.substring(3,6) }}-{{ student.extStudentId.substring(6,9) }}
            </a>
        </div>

        <div class="col-3 kt-widget28__tab-item kt-widget28__tab-link mt-0 py-3">
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Homeroom
                    </div>
                </div>
            </span>
            <span class="mb-0">
                {{ student.homeRoom }}
            </span>
        </div>

        <div class="col-3 kt-widget28__tab-item kt-widget28__tab-link mt-0 py-3">
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Grade Level
                    </div>
                </div>
            </span>
            <span class="mb-0">
                {{ student.gradeLevel }}
            </span>
        </div>

        <div v-if="encryptionEnabled" class="col-12">
            <div class="text-center pt-4">
                PII is currently encrypted. Please unlock to view.
            </div>
            <div class="text-center pt-4">
                <button
                    type="button"
                    style="min-width: 160px;"
                    class="btn btn-label-brand btn-bold btn-upper mt-4"
                    @click="decrypt()"
                >
                    Unlock PII
                </button>
            </div>
        </div>

        <div
            v-if="student.schoolEmail && !encryptionEnabled"
            class="col-12 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        School Email
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(student.schoolEmail)"
            >
                <i class="la la-copy mr-1" />
                {{ student.schoolEmail }}
            </a>
        </div>

        <div
            v-if="!$_userMixins_isGoogleDisabled && (student.googleEmail || student.unlinkedGoogleEmail) && !encryptionEnabled"
            class="col-12 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Google Email
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(student.googleEmail || student.unlinkedGoogleEmail)"
            >
                <i class="la la-copy mr-1" />
                {{ student.googleEmail || student.unlinkedGoogleEmail }}
            </a>
        </div>

        <div
            v-if="student.street && student.primaryGuardian && !encryptionEnabled"
            class="col-6 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Guardian
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(`${student.primaryGuardian}\n ${student.street}, ${student.city } ${student.state } ${student.zip }`)"
            >
                <i class="la la-copy mr-1" />
                {{ student.primaryGuardian }} <br>
                {{ student.street }}<br>
                {{ student.city }},
                {{ student.state }}
                {{ student.zip }}
            </a>
        </div>
        <div
            v-if="student.phoneNumber && !encryptionEnabled"
            class="col-6 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Phone Number
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(student.phoneNumber)"
            >
                <i class="la la-copy mr-1" />
                {{ student.phoneNumber }}
            </a>
        </div>

        <div
            v-if="student.homeLanguage && !encryptionEnabled"
            class="col-6 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Home Language
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(student.homeLanguage)"
            >
                <i class="la la-copy mr-1" />
                {{ student.homeLanguage }}
            </a>
        </div>
        <div
            v-if="student.cohortYear"
            class="col-6 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Entered 9th Grade
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(student.cohortYear)"
            >
                <i class="la la-copy mr-1" />
                {{ student.cohortYear }}
            </a>
        </div>

        <div
            v-if="student.adminDate && !encryptionEnabled"
            class="col-6 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Admission Date
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(student.adminDate)"
            >
                <i class="la la-copy mr-1" />
                {{ student.adminDate }}
            </a>
        </div>
        <div
            v-if="student.ethnicity && !encryptionEnabled"
            class="col-6 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Ethnicity
                    </div>
                </div>
            </span>
            <a
                href="#"
                title="Copy to Clipboard"
                class="mb-0"
                @click.stop.prevent="copyTextToClipboard(student.ethnicity)"
            >
                <i class="la la-copy mr-1" />
                {{ student.ethnicity }}
            </a>
        </div>
        <div v-if="!encryptionEnabled" class="col-6 kt-widget28__tab-item mt-0 py-3">
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        English Language Learner
                    </div>
                </div>
            </span>
            <span>{{ student.englishLearner ? "Yes" : "No" }}</span>
        </div>
        <div
            v-if="!encryptionEnabled"
            class="col-6 kt-widget28__tab-item mt-0 py-3"
        >
            <span>
                <div class="d-flex">
                    <div class="mr-auto">
                        Special Education Program
                    </div>
                </div>
            </span>
            <span>{{ student.specialEd ? "Yes" : "No" }}</span>
        </div>
    </div>
</div>
</template>

<script>
import StudentPortfolioAvatar from '../StudentPortfolioAvatar.vue';
import Types from '../../store/Types';
import { getStudentBadge, getUniqueEarnedBadges } from '../../store/mixins/badgeMixins';
import userMixins from '../../store/mixins/userMixins';
import { StudentFilter, getStudents } from '../../store/mixins/studentMixins';
import courseMixins from '../../store/mixins/courseMixins';
import portfolioMixins from '../../store/mixins/portfolioMixins';
import studentGuardianMixins from '../../store/mixins/studentGuardianMixins';
import { getStudentCourseMenuItems } from '../../router/routes/studentRoutes';
import StudentDetailCourseList from './StudentDetailCourseList.vue';
import StudentAnecdotalFilters from '../StudentAnecdotalFilters.vue';

export default {
    name: 'StudentDetailsPanel',
    components: {
        StudentPortfolioAvatar,
        StudentAnecdotalFilters,
        StudentDetailCourseList,
    },
    mixins: [
        userMixins,
        studentGuardianMixins,
        courseMixins,
        portfolioMixins,
    ],
    data() {
        return {
            selectedPane: null,
            // menuItems: [],
            // extCourseSectionId: null,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        studentBadge() {
            if (!this.student) return null;
            const { studentEnrollmentId } = this.student;
            return getStudentBadge(studentEnrollmentId, this.$store);
        },
        uniqueEarnedBadges() {
            if (!this.studentBadge) return [];
            return getUniqueEarnedBadges(this.studentBadge.earnedBadges) || [];
        },
        administrativeBadges() {
            if (!this.studentBadge) return [];
            return this.uniqueEarnedBadges.filter((b) => b.badge.badgeType === 'Administrative Badge');
        },
        academicAwards() {
            if (!this.studentBadge) return [];
            return this.uniqueEarnedBadges.filter((b) => b.badge.badgeType === 'Academic Award');
        },
        hasBadges() {
            return this.$store.state.database.badges.length > 0;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        clientSideEncryptionEnabled() {
            return this.user.school.clientSideEncryptionEnabled;
        },
        student() {
            const { studentEnrollmentId } = this.$route.query;
            const { database } = this.$store.state;
            const students = getStudents( database, new StudentFilter({ studentEnrollmentId }) );
            const [firstItem] = students;
            return firstItem || null;
        },
        course() {
            const { courseSectionId } = this.$route.query;
            if (!courseSectionId) return null;
            return this.$_courseMixins_getCourseById(courseSectionId);
        },
        extCourseSectionId() {
            const { course } = this;
            if (!course) return null;
            return course.extCourseSectionId;
        },
        forceStudentView: {
            get() {
                return this.$store.state.forceStudentView;
            },
            set(enabled) {
                this.$store.commit(Types.mutations.SET_FORCE_STUDENT_VIEW, enabled);
            },
        },
        isHomeUser() {
            if (this.forceStudentView) return true;
            return this.$_userMixins_isHomeUser;
        },
        canMessageHomeUsers() {
            const { user } = this;
            const { userPermissions } = user;
            return userPermissions.canMessageHomeUsers;
        },
    },
    watch: {
        $route() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        showStudentBadgesRoute() {
            this.$router.push({
                name: 'StudentBadges',
                params: {
                    studentEnrollmentId: this.student.studentEnrollmentId,
                },
            });
        },
        toggleCreateMessageToStudent() {
            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_STUDENT, this.student);
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
            this.$store.commit(Types.mutations.CLOSE_QUICK_PANEL);
        },
        toggleCreateMessageToGuardian() {
            const { studentEnrollmentId } = this.student;
            const guardians = this.$_studentGuardianMixins_getStudentGuardians({ studentEnrollmentId });
            if (!guardians || guardians.length === 0) {
                this.showError('No guardians found for this student');
                this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_GUARDIAN, null);
                this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
                this.$store.commit(Types.mutations.CLOSE_QUICK_PANEL);
                return;
            }

            const firstEnabled = guardians.find((g) => g.emailAddresses && g.emailAddresses.length && g.emailAddresses.some((e) => e.enableSyncGradesHome)) || guardians[0];
            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_GUARDIAN, firstEnabled);
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
            this.$store.commit(Types.mutations.CLOSE_QUICK_PANEL);
        },
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        newStudentBadge() {
            const studentEnrollmentId = this.$route.params.studentEnrollmentId || this.$route.query.studentEnrollmentId;
            const { commit, state } = this.$store;
            if (studentEnrollmentId) {
                const student = state.database.students.find((x) => x.studentEnrollmentId == studentEnrollmentId);
                if (!student) return commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN);
                return commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN, {
                    students: [student],
                    badgeType: null,
                });
            }
            commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN);
        },
        toggleCreateMessage() {
            this.$store.commit(Types.mutations.SET_CREATE_MESSAGE_STUDENT, this.student);
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
        },
        populate() {
            const selectedPane = this.$route.query.pane || 'courses';
            this.selectedPane = selectedPane;

            const routes = getStudentCourseMenuItems();
            this.menuItems = routes.map((i) => {
                const item = i;
                item.name = i.name;
                return item;
            });
        },
        newAnecdotal() {
            const { student } = this;
            this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN, { students: [student] });
        },
        selectPane(pane) {
            const { name, params } = this.$route;
            const query = this.$route.query || {};
            return this.$router.push({
                name,
                params,
                query: {
                    ...query,
                    pane,
                },
            });
        },
    },
};

</script>
<style scoped>
.kt-widget28__tab-link--copy:hover span {
    color: #0f88ef !important;
}
</style>


<style>
    div.student-details-panel div.kt-widget__username {
        pointer-events: none;
    }
    div.student-details-panel div.kt-widget__media {
        height: auto !important;
        width: auto !important;
    }
    div.student-details-panel img.kt-widget__img {
        height: auto !important;
        width: 160px !important;
        max-width: 160px !important;
        max-height: none !important;
    }
</style>